import React from 'react';
import logo from '../assets/images/Lenzify-Logo.svg';
import config from '../../config';


export default function Header({ title, heading, avatar }) {
  return (
    <section id="header">
      <header>
      <img src={logo} alt="lenzify" />
        <p>{config.heading}</p>
      </header>
      <footer>
      <p>Helping businesses apply contemporary approaches to customer and product development</p>
      </footer>
    </section>
  );
}
